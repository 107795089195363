<script setup>


</script>

<template>
  <section class="bg-dark" id="about">
    <div class="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
      <div class="text-center max-w-screen-md mb-8 lg:mb-16 mx-auto">
        <h2 class="section-title text-main-light">O nas</h2>
        <h2 class="section-header text-white mt-0 mb-12">Jak pracujemy?</h2>
        <p class="section-description">
          Tworząc strony internetowe stawiamy na dobrą komunikację i zrozumienie Twojego biznesu.
          Mamy ponad 10 lat doświadczenia w tworzeniu produktów internetowych.
        </p>
      </div>
      <div>
        <div class="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6">
          <img class="w-full rounded-lg" src="./../../assets/images/blue-graphic-1.webp" alt="dashboard image">
          <div class="mt-4 md:mt-0">
            <h2 class="mb-4 text-2xl font-extrabold text-white">Doskonała jakość oraz terminowość</h2>
            <p class="mb-6 font-light text-gray-300 md:text-lg">Realizujemy projekty zgodne z celami Klienta, na czas. Działamy szybko i skutecznie. Naszym priorytetem jest dostarczanie rozwiązań, które łączą wysoką jakość z pełnym zadowoleniem Klienta.</p>
          </div>
        </div>
        <div class="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6">
          <img class="w-full rounded-lg md:hidden" src="./../../assets/images/blue-graphic-2.webp" alt="dashboard image">
          <div class="mt-4 md:mt-0">
            <h2 class="mb-4 text-2xl font-extrabold text-white">Doświadczony zespół</h2>
            <p class="mb-6 font-light text-gray-300 md:text-lg">Nasz zespół dzieli się na specjalistów w konkretnych dziedzinach — dzięki temu masz pewność, że działania będą prowadzone przez doświadczone osoby specjalizujące się w określonej tematyce. Współpracując z nami, krok po kroku przejdziesz przez sprawdzony proces tworzenia strony internetowej. </p>
          </div>
          <img class="w-full rounded-lg hidden md:visible" src="./../../assets/images/blue-graphic-2.webp" alt="dashboard image">
        </div>
        <div class="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6">
          <img class="w-full rounded-lg" src="./../../assets/images/blue-graphic-3.webp" alt="dashboard image">
          <div class="mt-4 md:mt-0">
            <h2 class="mb-4 text-2xl font-extrabold text-white">Wsparcie po zakończeniu projektu</h2>
            <p class="mb-6 font-light text-gray-300 md:text-lg">Zapewniamy kompleksową oraz bezpłatną pomoc naszym Klientom na każdym etapie współpracy, również po realizacji projektu. Nasze wsparcie obejmuje także monitorowanie efektywności wdrożonych rozwiązań oraz szybkie reagowanie na wszelkie potrzeby i pytania po zakończeniu projektu.</p>
          </div>
        </div>
      </div>
    </div>
  </section>

</template>

<style scoped>

</style>
