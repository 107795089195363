<script >
export default {
  data() {
    return {
      images: [
          'influ-tool.svg',
          'social-auditor.svg',
          'neuromedic.png',
          'epower.png',
          'wymien-filtry.png',
          'flowear.png'
      ]
    };
  }
};
</script>

<template>
  <section class="bg-white">
    <div class="py-8 px-8 mx-auto max-w-screen-xl sm:py-24">
      <div class="text-center max-w-screen-md mb-8 lg:mb-16 mx-auto">
        <h2 class="section-title text-main-light">Zaufali nam</h2>
        <h2 class="section-header text-gray-900 mt-0 mb-12">Firmy, z którymi współpracujemy</h2>
        <p class="section-description">
          Projektujemy nowoczesne strony internetowe obsługując zarówno małe i średnie przedsiębiorstwa. Współpracujemy z takimi firmami, jak:
        </p>
      </div>
      <div class="mt-20 mb-10">
        <div class="mx-auto px-6 lg:px-8">
          <div class="mx-auto mt-10 flex flex-col sm:flex-row justify-center flex-wrap items-center gap-12">
            <img class="max-h-8 max-w-10 block object-contain" v-for="(image, index) in images" :key="index" :src="require(`@/assets/images/companies/${image}`)"/>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>

</style>
