<script setup>

</script>

<template>
  <footer class="bg-dark" id="footer">
    <div class="w-full max-w-screen-xl mx-auto px-8 py-12 md:py-16">
      <div class="sm:grid grid-cols-4">
        <div class="text-center sm:text-left">
          <a href="#main" class="">
            <img src="./../../assets/images/atp-logo.png" class="h-8 block mx-auto sm:mx-0" alt="ATP Solutions Logo"/>
          </a>
          <p class="text-white text-sm mt-4">Tworzymy strony, które rozwiną Twój biznes</p>
        </div>
        <div></div>
        <div>
          <div class="flex flex-col gap-6 items-center sm:items-start my-12 sm:my-0">
            <div class="flex gap-2 items-center">
              <div class="flex items-center w-4 h-4">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 text-main">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z" />
                </svg>
              </div>
              <a class="block text-white text-sm font-bold hover-scale hover-color" href="tel:+48782120445">
                782 120 445
              </a>
            </div>
            <div class="flex gap-2 items-center">
              <div class="flex items-center w-4 h-4">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 text-main">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
                </svg>
              </div>
              <a class="block text-white text-sm font-bold hover-scale hover-color" href="mailto:kontakt@atpsolutions.pl">
                kontakt@atpsolutions.pl
              </a>
            </div>
            <div class="flex gap-2 items-center">
              <div class="flex items-center w-4 h-4">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 text-main">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                  <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
                </svg>
              </div>
              <a class="block text-white text-sm font-bold">
                Łańcut/Rzeszów
              </a>
            </div>
          </div>
        </div>
        <ul class="flex flex-col gap-6 mb-12 text-sm font-medium text-white text-center md:text-right sm:mb-0">
          <li>
            <a href="#features" class="hover:underline me-4 md:me-6">Usługi</a>
          </li>
          <li>
            <a href="#about" class="hover:underline me-4 md:me-6">O nas</a>
          </li>
          <li>
            <a href="#contact" class="hover:underline">Kontakt</a>
          </li>
        </ul>
      </div>
      <div class="text-center mt-16">
        <span class="block text-sm text-white text-center">© 2024 ATP Solutions</span>

      </div>
    </div>
  </footer>

</template>

<style scoped>

</style>
